import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHero from "../images/gfx/hero-packages-shuttleid-scan-ticket.jpg"
import imgMobileHero from "../images/gfx/mobile-packages-hero.jpg"
import imgTick from "../images/gfx/tick.png"
import imgAddOn from "../images/gfx/addon.png"
import imgPackageOneBg from "../images/gfx/package-1-bg.png"
import imgPackageTwoBg from "../images/gfx/package-2-bg.png"
import imgPackageThreeBg from "../images/gfx/package-3-bg.png"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import "./packages.css";
import Nav from "../components/nav"
import Footer from "../components/footer"

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell'
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Packages by ShuttleID | Simple and Complete package for your different bus pass and ticketing needs" description="Simplify your home to school services with ShuttleID. Payments, ticket distribution and scanning using a simple but cost-effective approach." />
        <Nav activeTab="packages" />
        <div>
          <div>

            <div className='packages'>
              <div className="hero" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '60% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>Packages</h1>
                <h2>Designed for different needs and budgets</h2>
              </div>

              <div className="mobile-hero" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>Packages</h1>
                <h2>Designed for different needs and budgets</h2>
              </div>

              <div className="packages-page">

                <div className="package-group">
                  <div data-aos="fade-up" data-aos-duration="800" className="package package-a" style={{ backgroundImage: `url(${imgPackageOneBg})`, backgroundColor: '#063a70', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom' }}>
                    <h3>Bus Open Data Package</h3>
                    <p>A cost-effective solution for providing vehicle location data to the Bus Open Data Service. No ETM required.</p>
                    <p><Link to='/bus-open-data-vehicle-location-package/' className='btn'>View package</Link></p>
                  </div>
                  <div data-aos="fade-up" data-aos-duration="500" className="package package-b" style={{ backgroundImage: `url(${imgPackageTwoBg})`, backgroundColor: '#063a70', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom' }}>
                    <h3>Simple Package</h3>
                    <p>Bus pass scanning. For transport providers who want a simple solution for creating and scanning bus passes.</p>
                    <p><Link to='/simple-package/' className='btn'>View package</Link></p>
                  </div>
                  <div data-aos="fade-up" data-aos-duration="800" className="package package-c" style={{ backgroundImage: `url(${imgPackageThreeBg})`, backgroundColor: '#063a70', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom' }}>
                    <h3>Complete Package</h3>
                    <p>Sell bus passes and tickets online 24/7 with instant delivery. For efficient transport providers that work smarter not harder.</p>
                    <p><Link to='/complete-package/' className='btn'>View package</Link></p>
                  </div>
                </div>
                <div>
                  {/* <h4>Simple package <span style={{ fontSize: '0.5em', fontWeight: 400, position: 'relative', top: '-2px', color: 'red' }}>NEW</span></h4>
                  <p>For any operator happy with their existing data capture and payments process but likes the idea of self service bus passes and scanning.</p>
                  <h4>Complete package</h4>
                  <p>For the modern operator that believes in <Link to="/#automate">automating everything</Link>. Payments, self service tickets, scanning and more in a single system.</p> */}
                  <h4>Package comparison</h4>
                  <table className="package-comparison">
                    <thead>
                      <tr>
                        <th className="text-left">Feature</th>
                        <th>Bus Open Data</th>
                        <th>Simple</th>
                        <th>Complete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left">BODS AVL</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><Link to="/bus-open-data-vehicle-location-package/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                        <td><Link to="/bus-open-data-vehicle-location-package/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                      </tr>
                      <tr>
                        <td className="text-left"><Link to="/school-bus-tracking-system-app/">Live vehicle tracking</Link></td>
                        <td>-</td>
                        <td><Link to="/school-bus-tracking-system-app/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                        <td><Link to="/school-bus-tracking-system-app/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                      </tr>
                      <tr>
                        <td className="text-left"><Link to="/school-bus-tracking-system-app/">Boarding notifications</Link></td>
                        <td>-</td>
                        <td><Link to="/school-bus-tracking-system-app/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                        <td><Link to="/school-bus-tracking-system-app/"><img className="addon" src={imgAddOn} alt="Yes" /></Link></td>
                      </tr>
                      <tr>
                        <td className="text-left">Ticket production</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Ticket distribution</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Ticket voidance</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Ticket scanning</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Scan logs &amp; reporting</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">SMS service alerts</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Waiting list</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Annual passes</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Term passes</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Single tickets</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Return tickets</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Week tickets</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">4-Week tickets</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Flexi-bundle tickets</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Voucher codes</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Zonal pricing</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Automated renewals</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Payments by direct debit</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      <tr>
                        <td className="text-left">Payments by card</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr>
                      {/* <tr>
                        <td className="text-left">Single system</td>
                        <td>-</td>
                        <td>-</td>
                        <td><img className="tick" src={imgTick} alt="Yes" /></td>
                      </tr> */}
                      <tr>
                        <td>&nbsp;</td>
                        <td><Link className='btn' to='/bus-open-data-vehicle-location-package/'>Pricing</Link></td>
                        <td><Link className='btn' to='/simple-package/#pricing'>Pricing</Link></td>
                        <td><Link className='btn' to='/complete-package/#pricing'>Pricing</Link></td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <p><Link to='/book-demo/' className='btn'>Book demo <i className="fas fa-arrow-right"></i></Link></p> */}
                </div>
              </div>
            </div>

            <Contact />
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
